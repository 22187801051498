export const BudgetInterval: any = {
    first: {
        id: 1,
        value: 'Moins de 2000',
        text: '- 2 000 €',
    },
    second: {
        id: 2,
        value: 'Entre 2000 et 2500',
        text: '2 000 € à 2 500 €',
    },
    third: {
        id: 3,
        value: 'Entre 2500 et 3000',
        text: '2 500 € à 3 000 €',
    },
    forth: {
        id: 4,
        value: 'Plus de 3000',
        text: '+ 3 000 €',
    },
};
