

























import Vue from 'vue';

export default Vue.extend({
    props: ['currentStep'],
    name: 'Header',
    computed: {
        availablePlaces: function (): any {
            switch(this.currentStep){
                case 2:
                    return '21';
                case 3:
                    return '12';
                case 4:
                case 5:
                    return '9';
                case 6:
                case 7:
                case 8:
                    return '3';
            }
        },
    },
    methods: {
        goToHome() {
            if (this.$route.name != 'Home') {
                this.$router.push({ name: 'Home' }).catch(() => {});
            }
        },
    },
});
