




















































































































































































































































































































































































































































































































import Vue from 'vue';
import Map from './components/Map.vue';
import Modal from './components/Modal.vue';
import { EstablishmentType } from '@/models/establishmentType';
import { BudgetInterval } from '@/models/budgetInterval';
import { Situation } from '@/models/situation';
import DepartmentsDropdown from './components/DepartmentsDropdown.vue';
import CurrentSituationDropdown from './components/CurrentSituationDropdown.vue';
import RelationDropdown from './components/RelationDropdown.vue';
import Axios from 'axios';

export default Vue.extend({
  name: 'Main',
  components: {
    Map,
    Modal,
    DepartmentsDropdown,
    CurrentSituationDropdown,
    RelationDropdown,
  },
  data() {
    return {
      maxSelectedDepartments: 8, //Number maximum of departements that can be selected
      disabledBelgium: true, //If we want to disable Belgium from the departements selection
      currentStep: 1,
      showModalConditions: false,
      establishmentType: EstablishmentType,
      budgetInterval: BudgetInterval,
      situation: Situation,
      selectedDepartments: [] as any[],
      selectedEstablishmentType: null as typeof EstablishmentType,
      selectedBudgetInterval: null as typeof BudgetInterval,
      selectedSituation: null as typeof Situation,
      zipCode: null as any,
      isUrgent: true,
      infosForm: {
        gender: '',
        birthName: '',
        usageName: '',
        firstName: '',
        age: '',
        currentSituation: '',
      },
      contactForm: {
        lastName: '',
        name: '',
        email: '',
        phone: '',
        relation: '',
        conditions: '',
      },
      additionalInformation: '',
      invalidZipCode: false,
      infosFormInvalidField: [] as any,
      contactFormInvalidField: [] as any,
    };
  },
  watch: {
    currentStep(val, oldVal) {
      if (val != oldVal) {
        this.$emit('currentStep', val);
        if (val == 1 && this.selectedDepartments && this.selectedDepartments.length) {
          setTimeout(() => {
            this.$nextTick(() => {
              this.selectedDepartments.forEach((department) => {
                (this.$refs.departmentMap! as any).toggleDepartement(department);
              });
            });
          }, 600);
        }
      }
    },
  },
  computed: {
    disableGoToStep2: function (): any {
      return !this.selectedFranceDepartments.length && !this.selectedBelgiumDepartments.length;
    },
    disableGoToStep5: function (): any {
      return !this.zipCode || !this.selectedSituation;
    },
    disableGoToStep7: function (): any {
      return (
          !this.infosForm.gender ||
          !this.infosForm.birthName ||
          !this.infosForm.firstName ||
          !this.infosForm.age ||
          !this.infosForm.currentSituation
      );
    },
    disableGoToStep8: function (): any {
      return (
          !this.contactForm.lastName ||
          !this.contactForm.name ||
          !this.contactForm.email ||
          !this.contactForm.phone ||
          !this.contactForm.conditions ||
          !this.contactForm.relation
      );
    },
    fullName: function (): string {
      return `${this.contactForm.lastName} ${this.contactForm.name},`;
    },
    showUsageName: function (): any {
      return this.infosForm && this.infosForm.gender && this.infosForm.gender == 'Mme' ? true : false;
    },
    selectedFranceDepartments: function (): any {
      let department = this.selectedDepartments
          .filter((department) => {
            if (department.isFrance) {
              return true;
            }
          })
          .map((d) => d.uniqueId);
      return department || [];
    },
    selectedBelgiumDepartments: function (): any {
      let department = this.selectedDepartments
          .filter((department) => {
            if (!department.isFrance) {
              return true;
            }
          })
          .map((d) => d.uniqueId);
      return department || [];
    },
  },
  methods: {
    departmentClicked(selectedDepartment: any) {
      if (
          selectedDepartment.isFrance &&
          this.selectedFranceDepartments.indexOf(selectedDepartment.uniqueId) != -1
      ) {
        this.selectedDepartments = this.selectedDepartments.filter(
            (department) => department.uniqueId !== selectedDepartment.uniqueId
        );
        (this.$refs.departmentMap! as any).toggleDepartement(selectedDepartment);
        return;
      }

      if (
          !selectedDepartment.isFrance &&
          this.selectedBelgiumDepartments.indexOf(selectedDepartment.uniqueId) != -1
      ) {
        this.selectedDepartments = this.selectedDepartments.filter(
            (department) => department.uniqueId !== selectedDepartment.uniqueId
        );
        (this.$refs.departmentMap! as any).toggleDepartement(selectedDepartment);
        return;
      }

      if (this.selectedDepartments.length < this.maxSelectedDepartments) {
        this.selectedDepartments.push(selectedDepartment);
        (this.$refs.departmentMap! as any).toggleDepartement(selectedDepartment);
        return;
      }
    },
    goToStep5() {
      this.invalidZipCode = false;
      if (!this.disableGoToStep5) {
        let franceZipCodeRegex = /^(F-)?((2[A|B])|[0-9]{2})[0-9]{3}$/;
        let belgiumZipCodeRegex = /^[1-9]{1}[0-9]{3}$/;

        if (!this.zipCode.match(franceZipCodeRegex) && !this.zipCode.match(belgiumZipCodeRegex)) {
          this.invalidZipCode = true;
        }

        if (!this.invalidZipCode) {
          this.currentStep = 5;
        }
      }
    },
    goToStep7() {
      this.infosFormInvalidField = [];
      if (!this.disableGoToStep7) {
        if (
            !Number.isInteger(this.infosForm.age) &&
            !(parseInt(this.infosForm.age) >= 1 && parseInt(this.infosForm.age) <= 200)
        ) {
          this.infosFormInvalidField.push('age');
        }

        if (!this.infosFormInvalidField.length) {
          this.currentStep = 7;
        }
      }
    },
    goToStep8() {
      this.contactFormInvalidField = [];
      if (!this.disableGoToStep8) {
        let frenchRegex = /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/;
        let belgiumRegex = /^(?:(?:\+|00)32|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/;

        if (!this.contactForm.phone.match(frenchRegex) && !this.contactForm.phone.match(belgiumRegex)) {
          this.contactFormInvalidField.push('phone');
        }
        let emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!this.contactForm.email.match(emailRegex)) {
          this.contactFormInvalidField.push('email');
        }

        if (!this.contactFormInvalidField.length) {
          this.currentStep = 8;
        }
      }
    },
    async sendForm() {
      let url = 'https://urgence-maison-de-retraite.fr/apiForm.php';
      let ipUser = await Axios.get('https://api.ipify.org');

      this.$gtag.event('conversion', { send_to: 'AW-699900319/siuzCMa15KcDEJ_D3s0C' });


      Axios.get(url, {
        params: {
          inputDep: this.selectedDepartments.map((d) => `${d.value} - ${d.name}`).join(','), // (1) 00 - Name (2+) 01 - Name1,02 - Name2
          cnom: this.contactForm.lastName,
          cprenom: this.contactForm.name,
          cemail: this.contactForm.email,
          contactlink: this.contactForm.relation, // relation.name
          cphone: this.contactForm.phone,
          consentementVal: this.contactForm.conditions ? 1 : 0, // 0 or 1
          source: 'PAFF104',
          emailAR: 'lead66',
          userip: ipUser.data, // get IP from client

          //Premium Fields
          ccomment: this.additionalInformation,
          civilite_PA1: this.infosForm.gender,
          panom: this.infosForm.birthName,
          paorigname: this.infosForm.usageName,
          paprenom: this.infosForm.firstName,
          paydate: this.infosForm.age,
          pazipcode: this.zipCode,
          delai_urgence: this.isUrgent ? 1 : 0,
          pacurrentres: this.selectedSituation.value,
          typeres: this.selectedEstablishmentType.value,
          budget: this.selectedBudgetInterval.value,

          //New Fields
          // additionalInformation: this.additionalInformation,
          // infosGender: this.infosForm.gender,
          // infosBirthName: this.infosForm.birthName,
          // infosUsageName: this.infosForm.usageName,
          // infosFirstName: this.infosForm.firstName,
          // infosAge: this.infosForm.age,
          // zipCode: this.zipCode,
          // isUrgent: this.isUrgent,
          // currentSituation: this.selectedSituation.value,
          // establishmentType: this.selectedEstablishmentType.value,
          // budgetInterval: this.selectedBudgetInterval.value,
        },
      }).then(
          () => {
            //Success
            console.log('Success');
            this.currentStep = 9;
          },
          () => {
            //Error
            console.log('Error');
          }
      );
    },
  },
});
