export const Situation: any = {
    first: {
        id: 1,
        value: 'Domicile',
        text: 'À domicile',
    },
    second: {
        id: 2,
        value: 'Établissement de santé',
        text: 'Etablissement de santé',
    },
    third: {
        id: 3,
        value: 'MR / RS',
        text: 'Maison de retraite ou Résidence service',
    },
};
