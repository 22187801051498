export const EstablishmentType: any = {
    permanent: {
        id: 1,
        value: 'Permanent',
        text: 'Séjour permanent',
    },
    temporary: {
        id: 2,
        value: 'Temporaire',
        text: 'Séjour temporaire',
    }
};
